import React, { Component } from 'react';
import './App.css';
import firebase, { auth, provider } from './firebase.js';
const balance = require("./crypto-balance");

class App extends Component {
  constructor() {
    super();

    this.state = {
      cryptoAddress: '',
      balance:'Just put an address for any of the following: DASH, DOGE, LTC, & BTC',
      currency: '',
      username: '',
      items: [],
      user: null,
      disableBtc: false,
      disableDash: false,
      disableDgb: false,
      disableDoge: false,
      disableEth: false,
      disableLtc: false,
      disableNeo: false,
      disableStart: false,
      currencyList: [{value:"",text:"[Select]"},
                    {value:"BTC",text:"Bitcoin"},
                    {value:"DASH",text:"Dash"},
                    {value:"DGB",text:"DigiByte"},
                    {value:"DOGE",text:"Dogecoin"},
                    {value:"ETH",text:"Ethereum"},
                    {value:"LTC",text:"Litecoin"},
                    {value:"NEO",text:"NEO"},
                    {value:"STRAT",text:"Stratis"}],
      currencyListAll: [{value:"",text:"[Select]"},
                    {value:"BTC",text:"Bitcoin"},
                    {value:"DASH",text:"Dash"},
                    {value:"DGB",text:"DigiByte"},
                    {value:"DOGE",text:"Dogecoin"},
                    {value:"ETH",text:"Ethereum"},
                    {value:"LTC",text:"Litecoin"},
                    {value:"NEO",text:"NEO"},
                    {value:"STRAT",text:"Stratis"}]
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleDdlChange = this.handleDdlChange.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
  }

  render() {
    return (
      <div className='app'>
        <header>
            <div className='wrapper'>
              {this.state.user ?
                <div>
                <button onClick={this.logout}>Log Out</button>                
                <div className='user-profile'>
                  <img src={this.state.user.photoURL} />
                </div>
                </div>
                :
                <a  onClick={this.login} class="loginButton" href="#" target="_top">Sign in</a>
              }
            </div>
        </header>
        {this.state.user ?
          <div>
            <div className='container'>
              <section className='box'>
                <div class="box-content">
                  <div>
                    <div class="blu">A</div>
                    <div class="red">L</div>
                    <div class="yelo">O</div>
                    <div class="grin">check</div>
                  </div>
                  <br />
                  <br />
                  <input type="text" name="cryptoAddress" placeholder="Your crypto address" onChange={this.handleChange} />
                  <select id="coin" onChange={this.handleDdlChange}>
                  {
                    this.state.currencyList.map((c, i) => {
                      return <option value={c.value}>{c.text}</option>
                    })
                  }
                  </select>
                  <br />
                  <br />
                  <center>
                    <input
                        value="Get Balance"
                        aria-label="Get Balance"
                        name="btnK"
                        type="button"
                        class="search-btn"
                        title="Address and currency are both required"
                        onClick={this.checkBalance} />
                  </center>
                  <br />
                  <textarea name="balance" rows="5" value={this.state.balance} readOnly disabled ></textarea>

                </div>
              </section>
            </div>

            <div className='container'>
              <section className='add-item'>
                <form onSubmit={this.handleSubmit}>
                  <input type="text" name="username" placeholder="What's your name?" value={this.state.user.displayName || this.state.user.email} />
                  <button>Add Item</button>
                </form>
              </section>
            </div>
          </div>
          :
          <div className='wrapper'>
            <p>You must be logged in to check balances.</p>
          </div>
        }
      </div>
      
    );
  }

  isDisabled(v) {
    console.log(v);
    return false;
  }
  handleChange(e) {
    // currency
    console.log('handleChange()', e.target.value);
    const cur = e.target.value.trim();
    if (!cur.length) {
      this.setState({
      currencyList: this.currencyListAll
      });
  } else if (RegExp('^xpub[a-km-zA-HJ-NP-Z0-9]{107}$').test(cur)) {
      console.log("BTC");
      this.setState({
        currencyList: [{value:"BTC",text:"Bitcoin"}],
        currency: 'BTC'
      });
    } else if (RegExp('^[LXD13][a-km-zA-HJ-NP-Z0-9]{26,33}$').test(cur)) {
      console.log('"BTC", "LTC", "DASH", "DOGE"');
      this.setState({
        currencyList: [{value:"",text:"[Select]"},
                    {value:"BTC",text:"Bitcoin"},
                    {value:"DASH",text:"Dash"},
                    {value:"DOGE",text:"Dogecoin"},
                    {value:"LTC",text:"Litecoin"}],
        currency: ''
      });
    } else if (RegExp('^[SMD][a-km-zA-HJ-NP-Z0-9]{26,33}$').test(cur)) {
      console.log('"LTC", "STRAT", "DGB"');
      this.setState({
        currencyList: [{value:"",text:"[Select]"},
                    {value:"DGB",text:"DigiByte"},
                    {value:"LTC",text:"Litecoin"},
                    {value:"STRAT",text:"Stratis"}],
        currency: ''
      });
    } else if (RegExp('^(0x)?[0-9a-fA-F]{40}$').test(cur)) {
      console.log("ETH");
      this.setState({
        currencyList: [{value:"ETH",text:"Ethereum"}],
        currency: 'ETH'
      });
    } else if (RegExp('^A[0-9a-zA-Z]{33}$').test(cur)) {
      console.log("NEO");
      this.setState({
        currencyList: [{value:"NEO",text:"NEO"}],
        currency: 'NEO'
      });
    }

    this.setState({
      [e.target.name]: cur
    });
  }

  handleDdlChange(e) {
    console.log('handleDdlChange()', e.target.value);
    this.setState({
      currency: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const itemsRef = firebase.database().ref('items');
    const item = {
      title: this.state.cryptoAddress,
      user: this.state.user.displayName || this.state.user.email // this.state.username
    }
    itemsRef.push(item);
    this.setState({
      cryptoAddress: '',
      username: ''
    });
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } 
    });
    const itemsRef = firebase.database().ref('items');
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          user: items[item].user
        });
      }
      this.setState({
        items: newState
      });
    });
  }

  removeItem(itemId) {
    const itemRef = firebase.database().ref(`/items/${itemId}`);
    itemRef.remove();
  }

  logout = () => {
    auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
  }
  login() {
    auth.signInWithPopup(provider) 
      .then((result) => {
        const user = result.user;
        this.setState({
          user
        });
      });
  }

  checkBalance = () => {
    // alert("Hello World "+this.state.cryptoAddress);
    // this.setState({
    //   balance: this.state.cryptoAddress
    // });
    if (!this.state.cryptoAddress.trim() || !this.state.currency) {
      if (!this.state.cryptoAddress.trim() && !this.state.currency) {
        this.setState({balance: "Address and currency must be specified"});
      } else if (!this.state.cryptoAddress.trim()) {
        this.setState({balance: "Address must be specified"});
      } else if (!this.state.currency) {
        this.setState({balance: "Currency must be specified"});
      }
    } else {
      balance(this.state.cryptoAddress.trim(), this.state.currency)
      .then(items => {
        // console.log(items);
        if (!items || !items.address_type || items.address_type==="unknown"){
          this.setState({ balance: "Address not found!" });
        } else if (items.address_type==="BTC"){
          this.setState({ balance: items.balances.BTC + " " + items.address_type });
        } else if (items.address_type==="DASH"){
          this.setState({ balance: items.balances.DASH + " " + items.address_type });
        } else if (items.address_type==="DGB"){
          this.setState({ balance: items.balances.DGB + " " + items.address_type });
        } else if (items.address_type==="DOGE"){
          this.setState({ balance: items.balances.DOGE + " " + items.address_type });
        } else if (items.address_type==="ETH"){
          this.setState({ balance: items.balances.ETH + " " + items.address_type });
        } else if (items.address_type==="LTC"){
          this.setState({ balance: items.balances.LTC + " " + items.address_type });
        } else if (items.address_type==="NEO"){
          this.setState({ balance: items.balances.NEO + " " + items.address_type });
        } else if (items.address_type==="STRAT"){
          this.setState({ balance: items.balances.STRAT + " " + items.address_type });
        } else {
          this.setState({ balance: "Address not found!" });
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({
          balance: error.message
        });
      });
    }
  }
}

export default App;
