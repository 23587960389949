// src/firebase.js
import firebase from 'firebase'
const config = {
    apiKey: "AIzaSyB1BDAMmSj4Vkay7GvL9Odzg0_G7YROiMY",
    authDomain: "alo-check.firebaseapp.com",
    databaseURL: "https://alo-check.firebaseio.com",
    projectId: "project-529541828608"
    // storageBucket: "fun-food-friends-eeec7.appspot.com",
    // messagingSenderId: "144750278413"
};
firebase.initializeApp(config);

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export default firebase;